<template>
  <v-sheet
    color="white"
    class="mb-5"
    elevation="1"
    @keyup.enter="$emit('buscar')"
  >
    <v-container fluid>
      <v-row class="pa-3">
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="1"
        >
          <v-menu
            v-model="menuDataAcessoInicio"
            :nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-model="dataAcessoInicioFormatada"
                v-mask="'##/##/####'"
                label="Data Acesso Início"
                dense
                hide-details="auto"
                outlined
                append-icon="mdi-calendar"
                @blur="
                  atualizarFiltros(
                    'data_acesso_inicio',
                    formatarDataApi($event.target.value)
                  )
                "
                @click:append="menuDataAcessoInicio = true"
              />
            </template>
            <v-date-picker
              :value="value.campos.data_acesso_inicio"
              no-title
              class="ma-0"
              @input="atualizarFiltrosData('data_acesso_inicio', $event)"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="3"
        >
          <v-menu
            ref="menuHoraAcessoInicio"
            v-model="menuHoraAcessoInicio"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-mask="'##:##:##'"
                :value="value.campos.hora_acesso_inicio"
                label="Hora Acesso Início"
                outlined
                dense
                hide-details="auto"
                append-icon="mdi-clock"
                @input="atualizarFiltros('hora_acesso_inicio', $event)"
                @click:append="menuHoraAcessoInicio = true"
              />
            </template>
            <v-time-picker
              :value="value.campos.hora_acesso_inicio"
              class="ma-0"
              no-title
              use-seconds
              format="24hr"
              @input="atualizarFiltros('hora_acesso_inicio', $event)"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="6"
          order-lg="9"
        >
          <v-text-field
            id="url"
            :value="value.campos.url"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Url"
            @input="atualizarFiltros('url', $event)"
          />
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :value="value.campos.response_code"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Código de resposta"
            @input="atualizarFiltros('response_code', $event)"
          />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :value="value.campos.metodo"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Método"
            @input="atualizarFiltros('metodo', $event)"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1 d-flex align-center"
          order="11"
        >
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="2"
        >
          <v-menu
            v-model="menuDataAcessoFim"
            :nudge-right="40"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-model="dataAcessoFimFormatada"
                v-mask="'##/##/####'"
                label="Data Acesso Fim"
                dense
                hide-details="auto"
                outlined
                append-icon="mdi-calendar"
                @blur="
                  atualizarFiltros(
                    'data_acesso_fim',
                    formatarDataApi($event.target.value)
                  )
                "
                @click:append="menuDataAcessoFim = true"
              />
            </template>
            <v-date-picker
              :value="value.campos.data_acesso_fim"
              no-title
              class="ma-0"
              @input="atualizarFiltrosData('data_acesso_fim', $event)"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="4"
        >
          <v-menu
            ref="menuHoraAcessoFim"
            v-model="menuHoraAcessoFim"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{}">
              <v-text-field
                v-mask="'##:##:##'"
                :value="value.campos.hora_acesso_fim"
                label="Hora Acesso Fim"
                outlined
                dense
                hide-details="auto"
                append-icon="mdi-clock"
                @input="atualizarFiltros('hora_acesso_fim', $event)"
                @click:append="menuHoraAcessoFim = true"
              />
            </template>
            <v-time-picker
              :value="value.campos.hora_acesso_fim"
              class="ma-0"
              no-title
              use-seconds
              format="24hr"
              @input="atualizarFiltros('hora_acesso_fim', $event)"
            />
          </v-menu>
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :value="value.campos.endereco_ip"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Endereço Ip"
            @input="atualizarFiltros('endereco_ip', $event)"
          />
        </v-col>

        <v-col
          cols="6"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          class="pa-2 pa-lg-1"
          order="5"
        >
          <v-text-field
            :value="value.nome_pessoa"
            outlined
            dense
            autocomplete="off"
            hide-details="auto"
            clearable
            label="Nome Usuário"
            @input="atualizarFiltros('nome_pessoa', $event)"
          />
        </v-col>

        <v-col
          cols="6"
          sm="3"
          md="3"
          lg="1"
          xl="1"
          class="pa-2 pa-lg-1"
          order="10"
        >
          <v-btn
            class="mt-1 buscar-btn"
            color="primary"
            outlined
            :loading="carregando"
            @click="$emit('buscar')"
          >
            <v-icon class="botao-icone" left v-text="'mdi-magnify'" />
            Buscar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-linear
      v-if="carregando"
      color="primary"
      absolute
      indeterminate
    />
  </v-sheet>
</template>

<script>
import formatarData from "@/utils/formatar-data-input";

export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    },
    carregando: {
      type: Boolean,
      default: false
    },
    totalLeiturasSelecionadas: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      hover: false,
      menuDataAcessoInicio: false,
      menuHoraAcessoInicio: false,
      menuDataAcessoFim: false,
      menuHoraAcessoFim: false,
      dataInicioFormatada: null,
      dataFimFormatada: null,
    };
  },

  computed: {
    selecionarLeituras: {
      get() {
        return this.value.selecionarLeituras;
      },
      set(val) {
        this.atualizar("selecionarLeituras", val);
      }
    }
  },
  watch: {
    "value.campos.data_acesso_inicio": {
      immediate: true,
      handler(data) {
        this.dataAcessoInicioFormatada = formatarData.ptBr(data);
      }
    },
    "value.campos.data_acesso_fim": {
      immediate: true,
      handler(data) {
        this.dataAcessoFimFormatada = formatarData.ptBr(data);
      }
    }
  },

  methods: {
    atualizar(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },

    atualizarFiltros(chave, valor) {
      if (!valor) {
        delete this.value.campos[chave];
        return;
      }
      const filtros = {
        ...this.value.campos,
        [chave]: valor
      };

      this.$emit("input", { ...this.value, campos: filtros });
    },

    atualizarFiltrosData(chave, valor) {
      this.atualizarFiltros(chave, valor);
      this.menuDataAcessoInicio = false;
      this.menuDataAcessoFim = false;
    },
    formatarDataApi(data) {
      return formatarData.api(data);
    }
  }
};
</script>
<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.placa {
  position: relative;
}
pre {
  font-family: system-ui;
  margin: 0;
}
.placasModal {
  position: absolute;
  background-color: #d7d7d7;
  height: 255px;
  left: -50%;
  top: 45px;
  padding: 1em;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  color: #363636;
  border-radius: 5px;
  z-index: 500;
}
.linha1,
.linha3,
.linha6,
.linha7,
.linha8 {
  background-color: white;
}
.placasModal pre span {
  color: red;
}
.placasModal pre {
  font-size: 1em;
  padding: 0.3em;
}
.linha1 {
  color: white;
  background-color: #363636;
}
.linha4,
.linha5,
.linha6,
.linha7,
.linha8 {
  padding: 0 !important;
}
.linha4,
.linha6 {
  padding-top: 0.3em !important;
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}
.linha5,
.linha8,
.linha9 {
  padding-bottom: 0.3em !important;
  padding-left: 0.3em !important;
  padding-right: 0.3em !important;
}
@media (max-width: 959px) {
  .buscar {
    justify-content: center;
  }
  .placasModal {
    display: none;
  }
}
@media (max-width: 1264) {
  .placasModal {
    left: -20%;
    top: 50;
  }
}
@media (min-width: 1264px) and (max-width: 1400px) {
  .buscar-btn {
    width: 80px !important;
  }
  .botao-icone {
    margin: 0 !important;
  }
}
</style>
